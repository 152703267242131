import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Button } from "@components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover";
import { Separator } from "@components/ui/separator";
import {
  ArrowLongRightIcon,
  ChevronUpDownIcon,
  LinkIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useUpdateAdSubAccounts } from "@lib/api-hooks/useAdAccounts";
import { useAdAccountsByOrganisation } from "@lib/api-hooks/useAdAccounts/useAdAccountsByOrganisation";
import { usePatchAdAccountCsids } from "@lib/api-hooks/useAdAccounts/usePatchAdAccountsCsids";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { cn } from "@lib/utils";
import { CheckIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Note, Spinner } from "@ui";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@components/ui/dialog";
import { Input } from "@components/ui/input";
import { selectVariants } from "@components/ui/select";
import InitializeAdAccountFlowModal from "@components/Dashboards/SettingsDashboard/InitializeAdAccountFlowModal";
import { integrations } from "@components/Dashboards/SettingsDashboard/IntegrationBlock";

type Props = {
  onClose: () => void;
  open: boolean;
  type: "google" | "facebook" | "tiktok" | "pinterest";
  enableAddNewAccount?: boolean;
};

const AssignAdAccountModal = ({
  onClose,
  open,
  type,
  enableAddNewAccount = false,
}: Props) => {
  const [storeErrorMessage, setStoreErrorMessage] = useState("");
  const {
    selectedStoreIds,
    stores,
    selectedOrganisationData,
    isPending: isPendingSelectedStores,
  } = useSelectedStores();
  const customerSites = selectedOrganisationData?.customerSites.map(
    (el) => el.id
  );
  const [openConnectAdAccountModal, setOpenConnectAdAccountModal] =
    useState(false);
  const { data: adAccounts, isPending: isLoadingAdAccounts } =
    useAdAccountsByOrganisation(
      {
        organisationId: selectedOrganisationData?.id ?? "",
        source: type,
      },
      { enabled: open }
    );

  const updateAdAccountMutation = usePatchAdAccountCsids();
  const organisationStores = stores?.filter(
    (el) => customerSites?.includes(el.csid)
  );
  const [accountToStoresMapping, setAccountToStoresMapping] = useState<{
    [key: string]: string[];
  }>(() => {
    const mapping: Record<string, string[]> = {};
    adAccounts?.forEach((account) => {
      mapping[account.id] = account.associatedCsids;
    });
    return mapping;
  });
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (initialized) return;
    if ((!adAccounts || adAccounts?.length === 0) && isLoadingAdAccounts)
      return;
    if (isPendingSelectedStores) return;
    if (!selectedOrganisationData?.id) return;

    setInitialized(true);
    const mapping: Record<string, string[]> = {};
    adAccounts?.forEach((account) => {
      mapping[account.id] = account.associatedCsids;
    });
    setAccountToStoresMapping(mapping);
  }, [
    adAccounts,
    initialized,
    isLoadingAdAccounts,
    selectedOrganisationData?.id,
    isPendingSelectedStores,
  ]);

  const [filter, setFilter] = useState("");

  const filteredAccounts = adAccounts
    ? !filter
      ? adAccounts
      : adAccounts?.filter((el) => {
          return (
            el.name?.toLowerCase().includes(filter.toLowerCase()) ||
            el.id.includes(filter)
          );
        })
    : [];

  const handleConnect = async () => {
    try {
      const promises = [];
      for (const [adAccountId, csids] of Object.entries(
        accountToStoresMapping
      )) {
        const adAccount = adAccounts?.find((el) => el.id === adAccountId);
        if (!adAccount) continue;

        const hasChanged =
          JSON.stringify(adAccount.associatedCsids) !== JSON.stringify(csids);
        if (!hasChanged) continue;
        promises.push(
          updateAdAccountMutation.mutateAsync({
            source: adAccount.source,
            accountId: adAccount.id,
            csids,
          })
        );
      }
      await Promise.all(promises);
      handleClose();
    } catch (error) {
      setStoreErrorMessage(
        (error as any)?.message ??
          "An error occurred while connecting accounts."
      );
    }
  };

  const noAccounts = adAccounts?.length === 0 && !isLoadingAdAccounts;
  const connectButtonDisabled = integrations[type].disabled;
  const connectAdAccountButton = (
    <Button
      variant={noAccounts ? "primary" : "outline"}
      disabled={connectButtonDisabled}
      onClick={() => setOpenConnectAdAccountModal(true)}
    >
      {noAccounts ? (
        <span className="flex items-center">Connect Account</span>
      ) : (
        <span className="flex items-center">Connect New Account</span>
      )}
    </Button>
  );

  const handleClose = () => {
    setAccountToStoresMapping({});
    setInitialized(false);
    onClose();
  };

  return (
    <>
      <InitializeAdAccountFlowModal
        open={openConnectAdAccountModal}
        onClose={() => setOpenConnectAdAccountModal(false)}
        type={type === "facebook" ? "fb" : type}
        integration={integrations[type]}
      />
      <Dialog
        open={open}
        onOpenChange={(open) => {
          setAccountToStoresMapping({});
          setInitialized(false);
          if (!open) {
            onClose();
          }
        }}
      >
        <DialogContent className="max-w-3xl w-full max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Account Assignment</DialogTitle>
          </DialogHeader>

          {!initialized ? (
            <div className="w-full flex flex-col items-center justify-center gap-4 p-4 bg-gray-200 dark:bg-gray-700 rounded-md">
              <Spinner />
              <p className="">Loading Ad account data</p>
            </div>
          ) : noAccounts ? (
            <div className="w-full flex flex-col items-center justify-center gap-4 p-4 bg-gray-200 dark:bg-gray-700 rounded-md">
              <p className="">
                No ad accounts found or existing ad accounts need to be
                re-connected.
              </p>
              {connectAdAccountButton}
            </div>
          ) : (
            <>
              <div className="flex flex-col min-h-[45vh]">
                <p className="text-sm text-muted-foreground">
                  Now that your account has been successfully connected to your
                  organization, you are able to connect each one of ad accounts
                  to their specific stores.
                </p>
                <p className="text-sm text-muted-foreground">
                  You don&apos;t have to connect every ad account, just the ones
                  out of which Tracify should pull data.
                </p>
                <div className="mt-4 gap-4 grid grid-cols-[1fr,48px,1fr] items-center">
                  <Input
                    placeholder="Search"
                    value={filter}
                    icon={<MagnifyingGlassIcon className="w-5 h-5" />}
                    width="100%"
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="col-span-1" />
                  <div className="col-span-1 flex justify-end">
                    {enableAddNewAccount ? connectAdAccountButton : null}
                  </div>

                  {filteredAccounts?.map((el) => (
                    <React.Fragment key={el.id}>
                      <div>
                        <p className="font-medium text-sm">{el.name}</p>
                        <p className="text-xs italic text-gray-800 dark:text-gray-100">
                          {el.id}
                        </p>
                      </div>
                      <div>
                        <ArrowLongRightIcon className="h-6 w-6" />
                      </div>
                      <MultiSelect
                        options={[
                          ...(organisationStores?.map((store) => ({
                            label: store.description,
                            value: store.csid,
                          })) ?? []),
                        ]}
                        onValueChange={(value) => {
                          setAccountToStoresMapping((curr) => {
                            return { ...curr, [el.id]: value };
                          });
                        }}
                        value={accountToStoresMapping[el.id] ?? []}
                        placeholder="Select stores"
                        maxCount={3}
                      />
                    </React.Fragment>
                  ))}
                </div>
                <div className="flex-1" />
                {storeErrorMessage && (
                  <p className="mt-4 font-semibold text-destructive">
                    {storeErrorMessage}
                  </p>
                )}
                <DialogFooter className="mt-4">
                  <LinkWithPersistentQuery
                    href="/settings/integrations"
                    passHref
                  >
                    <Button
                      variant="ghost"
                      disabled={updateAdAccountMutation.isPending}
                      onClick={() => onClose()}
                    >
                      Discard
                    </Button>
                  </LinkWithPersistentQuery>
                  <Button
                    loading={updateAdAccountMutation.isPending}
                    onClick={handleConnect}
                    disabled={updateAdAccountMutation.isPending}
                  >
                    {updateAdAccountMutation.isPending ? (
                      <Spinner className="mr-2 h-4 w-4" />
                    ) : null}
                    Connect selected accounts
                  </Button>
                </DialogFooter>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignAdAccountModal;

interface MultiSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  onValueChange: (value: string[]) => void;
  value: string[];
  placeholder?: string;
  label?: string;
  animation?: number;
  maxCount?: number;
  modalPopover?: boolean;
  asChild?: boolean;
  className?: string;
  variant?: "default" | "alternate";
}

export const MultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      onValueChange,
      value = [],
      placeholder = "Select options",
      label = "Stores",
      animation = 0,
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      className,
      variant,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const toggleOption = (option: string) => {
      const newSelectedValues = value.includes(option)
        ? value.filter((value) => value !== option)
        : [...value, option];
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            className={cn(
              selectVariants({ variant: variant ?? "default" }),
              "min-h-12 h-auto active:ring-transparent",
              className
            )}
          >
            {value.length > 0 ? (
              <div className="flex justify-between items-center flex-1">
                <div className="flex flex-wrap items-center gap-1">
                  {value.slice(0, maxCount).map((value) => {
                    const option = options.find((o) => o.value === value);
                    return (
                      <p
                        className="text-sm py-0.5 px-2 rounded-md bg-gray-50 dark:bg-gray-600 truncate max-w-[200px]"
                        key={value}
                      >
                        {option?.label ?? ""}
                      </p>
                    );
                  })}
                  {value.length > maxCount && (
                    <p>&nbsp;(+{value.length - maxCount})</p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <XMarkIcon
                    className="h-4 mx-2 cursor-pointer text-muted-foreground"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation="vertical"
                    className="flex min-h-6 h-full"
                  />
                  <ChevronUpDownIcon className="h-5 cursor-pointer ml-2 text-primary" />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between w-full mx-auto">
                <span className="text-sm text-muted-foreground">
                  {placeholder}
                </span>
                <ChevronUpDownIcon className="h-5 cursor-pointer ml-2" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto min-w-64 p-0"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <p className="font-semibold text-sm mb-1 mt-2 mx-3">{label}</p>
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = value.includes(option.value);
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      className="cursor-pointer flex justify-between"
                    >
                      <span>{option.label}</span>
                      <CheckIcon
                        className={cn(
                          "ml-2 h-5 w-5",
                          isSelected ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup>
                <div className="flex items-center justify-between">
                  {value.length > 0 && (
                    <>
                      <CommandItem
                        onSelect={handleClear}
                        className="flex-1 justify-center cursor-pointer"
                      >
                        Clear
                      </CommandItem>
                      <Separator
                        orientation="vertical"
                        className="flex min-h-6 h-full"
                      />
                    </>
                  )}
                  <CommandItem
                    onSelect={() => setIsPopoverOpen(false)}
                    className="flex-1 justify-center cursor-pointer max-w-full"
                  >
                    Close
                  </CommandItem>
                </div>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
MultiSelect.displayName = "MultiSelect";
