import { CONSTANT_LABELS } from "@/constants/constants";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import ReconnectAdAccountsModal from "@components/ReconnectAdAccountsModal/ReconnectAdAccountsModal";
import { Banner, BannerMessage } from "@components/ui/banner";
import { Button } from "@components/ui/button";
import { useShopDomains } from "@lib/api-hooks/useShopDomains/useShopDomains";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Store } from "@api/types/backendTypes";
import { useEffect, useMemo, useState } from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import { dismissedMessagesAtom } from "@/atoms";
import { Organisation } from "@lib/api-hooks/useOrganisations";
import { ShopDomain } from "@lib/api-hooks/useShopDomains";
import { AdAccount } from "@lib/api-hooks/useAdAccounts/types";

interface MessageDismissal {
  dismissedAt: number;
}

export interface DismissedMessages {
  [organisationId: string]: {
    [messageId: string]: MessageDismissal;
  };
}

// Define dismissal durations for each message type
const MESSAGE_DISMISSAL_DURATIONS = {
  "first-party-tracking": 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
  "reconnect-ad-accounts": Infinity, // This message will never be dismissed
} as const;

function isMessageDismissed(
  dismissedMessages: DismissedMessages,
  organisationId: string,
  messageId: string
): boolean {
  const dismissal = dismissedMessages[organisationId]?.[messageId];
  if (!dismissal) return false;
  const dismissalDuration =
    MESSAGE_DISMISSAL_DURATIONS[
      messageId as keyof typeof MESSAGE_DISMISSAL_DURATIONS
    ] ?? 24 * 60 * 60 * 1000;
  if (dismissalDuration === Infinity) return false;

  const now = Date.now();

  return now - dismissal.dismissedAt < dismissalDuration;
}

function getFirstPartyTrackingMessage(
  stores: Store[],
  selectedOrganisationData: Organisation,
  domains: ShopDomain[]
): BannerMessage | null {
  const organisationStores = stores.filter((store) =>
    selectedOrganisationData.customerSites.some(
      (site) => site.id === store.csid
    )
  );

  const storesWithoutFirstPartyTracking = organisationStores.filter(
    (store) =>
      store.active &&
      (store.shop_system === "shopify" ||
        store.shop_system === "shopify_plus" ||
        store.shop_system === "custom") &&
      !domains.some((domain) => domain.customer_site_ids.includes(store.csid))
  );

  const firstPartyTrackingDomains = domains.filter((domain) =>
    domain.customer_site_ids.some((csid) =>
      organisationStores.some((store) => store.csid === csid)
    )
  );

  const allEntriesAreSet = firstPartyTrackingDomains.every(
    (domain) =>
      domain.script_subdomain_configured &&
      domain.ip4_subdomain_configured &&
      domain.ingest_subdomain_configured
  );

  if (allEntriesAreSet && storesWithoutFirstPartyTracking.length === 0) {
    return null;
  }

  return {
    id: "first-party-tracking",
    type: "default",
    message: (
      <p className="text-xs">
        First Party Tracking adds another tool to our tracking toolkit which
        makes tracking even less blockable. Users utilizing it usually see
        better tracking results.
      </p>
    ),
    dismissible: true,
    action: (
      <a
        href="https://knowledge.tracify.ai/en/articles/149611-how-to-configure-first-party-domain-tracking"
        target="_blank"
        className="underline text-sm"
      >
        Setup guide
      </a>
    ),
  };
}

function getReconnectMessage(
  notSelfOnboardedAccounts: AdAccount[],
  setIsReconnectModalOpen: (open: boolean) => void
): BannerMessage | null {
  if (notSelfOnboardedAccounts.length === 0) return null;

  const notSelfOnboardedChannels = [
    ...new Set(notSelfOnboardedAccounts.map((el) => el.source)),
  ];

  return {
    id: "reconnect-ad-accounts",
    type: "warning",
    dismissible: false,
    message: (
      <p className="!text-xs">
        To be able to show you data like the ad spend from your{" "}
        {notSelfOnboardedChannels
          .map((el) => CONSTANT_LABELS[el] ?? el)
          .join(", ")}{" "}
        ad account(s), please reconnect them by clicking the button to the
        right.{" "}
        <u
          className="cursor-pointer"
          onClick={() => setIsReconnectModalOpen(true)}
        >
          Why?
        </u>
      </p>
    ),
    action: (
      <LinkWithPersistentQuery href="/settings/integrations">
        <Button variant="primary" size="xs">
          Reconnect
        </Button>
      </LinkWithPersistentQuery>
    ),
  };
}

const DashboardBanner = () => {
  const { notSelfOnboardedAccounts, selectedOrganisationData, stores } =
    useSelectedStores();
  const [isReconnectModalOpen, setIsReconnectModalOpen] = useState(false);
  const [dismissedMessages, setDismissedMessages] = useAtom(
    dismissedMessagesAtom
  );
  const { data: domains } = useShopDomains({
    enabled: Boolean(selectedOrganisationData),
  });

  // Generate all possible messages based on current state
  const allMessages = useMemo(() => {
    // Only show messages when all required data is loaded
    if (!selectedOrganisationData || !stores || !domains) {
      return [];
    }

    const messages = [
      getFirstPartyTrackingMessage(stores, selectedOrganisationData, domains),
      getReconnectMessage(notSelfOnboardedAccounts, setIsReconnectModalOpen),
    ].filter((message): message is BannerMessage => message !== null);

    // Filter out messages that were dismissed less than 24 hours ago
    return messages.filter(
      (message) =>
        !isMessageDismissed(
          dismissedMessages,
          selectedOrganisationData.id,
          message.id
        )
    );
  }, [
    selectedOrganisationData,
    stores,
    domains,
    notSelfOnboardedAccounts,
    dismissedMessages,
  ]);

  const handleDismiss = (messageId: string) => {
    if (!selectedOrganisationData?.id) return;

    setDismissedMessages((prev) => ({
      ...prev,
      [selectedOrganisationData.id]: {
        ...prev[selectedOrganisationData.id],
        [messageId]: {
          dismissedAt: Date.now(),
        },
      },
    }));
  };

  return (
    <>
      <ReconnectAdAccountsModal
        isOpen={isReconnectModalOpen}
        setIsOpen={setIsReconnectModalOpen}
      />
      <Banner messages={allMessages} onDismiss={handleDismiss} />
    </>
  );
};

export default DashboardBanner;
