import { differenceInCalendarDays } from "date-fns";
import { DatePickerRangeInterface } from "../interface/DateRangeInterface";

import { isEmpty as isObjectEmpty } from "../lib/util-functions/object";
import dayjs from "dayjs";
import { START_DATE_RANGE_SELECT_OPTIONS_FULL } from "constants/constants";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

function getLastMonth() {
  const currentDate = new Date();

  if (currentDate.getMonth() === 0) {
    return {
      startDate: dayjs(new Date(currentDate.getFullYear() - 1, 11, 1))
        .startOf("month")
        .toDate(),
      endDate: dayjs(new Date(currentDate.getFullYear() - 1, 11, 1))
        .endOf("month")
        .toDate(),
      key: "selection",
    };
  }

  return {
    startDate: dayjs(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    )
      .startOf("month")
      .toDate(),
    endDate: dayjs(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    )
      .endOf("month")
      .toDate(),
    key: "selection",
  };
}

export const getStartDateRange = (
  key: string,
  selectedDateRange?: DatePickerRangeInterface
) => {
  switch (key) {
    case "today":
      return {
        startDate: dayjs(new Date()).startOf("day").toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        key: "selection",
      };

    case "yesterday":
      return {
        startDate: dayjs(new Date()).subtract(1, "day").startOf("day").toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "last_7_days":
      return {
        startDate: dayjs(new Date()).subtract(7, "day").startOf("day").toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "this_month":
      return {
        startDate: dayjs(new Date()).startOf("month").toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        key: "selection",
      };

    case "last_month":
      return getLastMonth();

    case "last_10_days":
      return {
        startDate: dayjs(new Date())
          .subtract(11, "day")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "last_14_days":
      return {
        startDate: dayjs(new Date())
          .subtract(14, "day")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "last_30_days":
      return {
        startDate: dayjs(new Date())
          .subtract(30, "day")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "last_15_days":
      return {
        startDate: dayjs(new Date())
          .subtract(16, "day")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).subtract(1, "day").endOf("day").toDate(),
        key: "selection",
      };

    case "last_3_months":
      return {
        startDate: dayjs(new Date())
          .subtract(3, "month")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        key: "selection",
      };

    case "last_6_months":
      return {
        startDate: dayjs(new Date())
          .subtract(6, "month")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        key: "selection",
      };

    case "last_1_year":
      return {
        startDate: dayjs(new Date())
          .subtract(1, "year")
          .startOf("day")
          .toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        key: "selection",
      };

    case "Custom":
      return {
        startDate: dayjs(
          key === "Custom" && selectedDateRange?.startDate
            ? selectedDateRange?.startDate
            : new Date()
        )
          .startOf("day")
          .toDate(),
        endDate: dayjs(
          key === "Custom" && selectedDateRange?.endDate
            ? selectedDateRange?.endDate
            : new Date()
        )
          .endOf("day")
          .toDate(),
        key: "selection",
      };

    default:
      return {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      };
  }
};

export const getPreviousDateRange = (key: string, currentDateRange: any) => {
  if (isObjectEmpty(currentDateRange)) {
    return { ...currentDateRange };
  }

  const { startDate, endDate } = currentDateRange;
  switch (key) {
    case "last_period":
      const dayDifference = dayjs(new Date(endDate)).diff(
        new Date(startDate),
        "day"
      );
      return {
        ...currentDateRange,
        startDate: startDate
          ? dayjs(startDate).subtract(dayDifference + 1, "day")
          : startDate,
        endDate: endDate
          ? dayjs(endDate).subtract(dayDifference + 1, "day")
          : endDate,
      };
    case "last_week":
      return {
        ...currentDateRange,
        startDate: startDate ? dayjs(startDate).subtract(1, "week") : startDate,
        endDate: endDate ? dayjs(endDate).subtract(1, "week") : endDate,
      };
    case "last_month":
      return {
        ...currentDateRange,
        startDate: startDate
          ? dayjs(startDate).subtract(1, "month")
          : startDate,
        endDate: endDate ? dayjs(endDate).subtract(1, "month") : endDate,
      };
    case "last_quarter":
      return {
        ...currentDateRange,
        startDate: startDate
          ? dayjs(startDate).subtract(1, "quarter")
          : startDate,
        endDate: endDate ? dayjs(endDate).subtract(1, "quarter") : endDate,
      };

    case "last_year":
      return {
        ...currentDateRange,
        startDate: startDate ? dayjs(startDate).subtract(1, "year") : startDate,
        endDate: endDate ? dayjs(endDate).subtract(1, "year") : endDate,
      };

    default:
      return { ...currentDateRange };
  }
};

// export const START_DATE_RANGE_SELECT_OPTIONS_BASIC = [
//   { label: "Today", value: "today" },
//   { label: "Yesterday", value: "yesterday" },
//   { label: "Last 7 days", value: "last_7_days" },
//   { label: "Last 14 days", value: "last_14_days" },
//   { label: "Last 30 days", value: "last_30_days" },
//   { label: "This Month", value: "this_month" },
//   { label: "Last Month", value: "last_month" },
// ] as const;

export const getTimerangeFromDates = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  let timerange: (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"] =
    "Custom";
  if (
    dayjs(startDate).isSame(dayjs(), "day") &&
    dayjs(endDate).isSame(dayjs(), "day")
  ) {
    timerange = "today";
  } else if (
    dayjs(startDate).isSame(dayjs().subtract(1, "day"), "day") &&
    dayjs(endDate).isSame(dayjs().subtract(1, "day"), "day")
  ) {
    timerange = "yesterday";
  } else if (
    dayjs(startDate).isSame(dayjs().subtract(7, "day"), "day") &&
    dayjs(endDate).isSame(dayjs().subtract(1, "day"), "day")
  ) {
    timerange = "last_7_days";
  } else if (
    dayjs(startDate).isSame(dayjs().subtract(14, "day"), "day") &&
    dayjs(endDate).isSame(dayjs().subtract(1, "day"), "day")
  ) {
    timerange = "last_14_days";
  } else if (
    dayjs(startDate).isSame(dayjs().subtract(30, "day"), "day") &&
    dayjs(endDate).isSame(dayjs().subtract(1, "day"), "day")
  ) {
    timerange = "last_30_days";
  } else if (
    dayjs(startDate).isSame(dayjs().startOf("month"), "day") &&
    dayjs(endDate).isSame(dayjs(), "day")
  ) {
    timerange = "this_month";
  } else if (
    dayjs(startDate).isSame(
      dayjs().subtract(1, "month").startOf("month"),
      "day"
    ) &&
    dayjs(endDate).isSame(dayjs().subtract(1, "month").endOf("month"), "day")
  ) {
    timerange = "last_month";
  }

  return timerange;
};
