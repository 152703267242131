import { Siteref } from "@api/types/backendTypes";
import AssignAdAccountModal from "@components/AdAccounts/AssignAdAccountModal";
import ConnectAdAccountModal from "@components/AdAccounts/ConnectAdAccountModal";
import SettingsIcon from "@components/Layout/icons/settings-icon";
import { Button } from "@components/ui/button";

import {
  ChevronDownIcon,
  InformationCircleIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useAuth } from "@lib/api-hooks";
import {
  useRequestCreateSiteref,
  useSiteRefs,
} from "@lib/api-hooks/useSiteRefs";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Spinner, Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import InitializeAdAccountFlowModal from "./InitializeAdAccountFlowModal";
import { AdAccount } from "@lib/api-hooks/useAdAccounts/types";
import { useAdAccounts } from "@lib/api-hooks/useAdAccounts/useAdAccounts";

type Props = {
  type: "google" | "fb" | "tiktok" | "pinterest";
};

export type Integration = {
  name: string;
  img: string;
  buttonText: string;
  disabled: boolean;
};

type StoreToAdAccountMap = {
  stores: string[];
  adAccount: AdAccount | null;
};

export const integrations: Record<string, Integration> = {
  google: {
    name: "Google Ads",
    img: "/static/logos/google-logo.webp",
    buttonText: "Connect Google Ads Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
  fb: {
    name: "Meta",
    img: "/static/logos/meta-logo.png",
    buttonText: "Connect Meta Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
  facebook: {
    name: "Meta",
    img: "/static/logos/meta-logo.png",
    buttonText: "Connect Meta Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
  tiktok: {
    name: "TikTok",
    img: "/static/logos/tiktok-logo.png",
    buttonText: "Connect TikTok Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
  pinterest: {
    name: "Pinterest",
    img: "/static/logos/pinterest-logo.png",
    buttonText: "Connect Pinterest Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
  outbrain: {
    name: "Outbrain",
    img: "/static/logos/outbrain-logo.svg",
    buttonText: "Connect Outbrain Account",
    // disabled: DEPLOY_ENVIRONMENT !== "production", // integrations are only enabled in production
    disabled: false,
  },
};

const MAX_REFS_TO_SHOW = 3;

const IntegrationBlock = ({ type }: Props) => {
  const { data: siterefs, isPending } = useSiteRefs({});
  const { data: auth } = useAuth();
  const router = useRouter();
  const asPath = router.asPath;
  const {
    selectedStoreIds,
    onStoreChanged,
    stores,
    notSelfOnboardedAccounts,
    selectedOrganisationData,
  } = useSelectedStores();

  const {
    data: adAccounts,
    isPending: isAdAccountsPending,
    isRefetching: isAdAccountsRefetching,
  } = useAdAccounts();

  const [showPartiallyConnectedMore, setShowPartiallyConnectedMore] =
    useState(false);
  const customerSiteIds = selectedOrganisationData?.customerSites?.map(
    (el) => el.id
  );
  const organisationStores = stores?.filter(
    (el) => customerSiteIds?.includes(el.csid)
  );

  const requestSiterefCreateMutation = useRequestCreateSiteref();
  const [storeErrorMessage, setStoreErrorMessage] = useState("");
  const [selectedStoreModalOpen, setSelectStoreModalOpen] = useState(false);
  const [assignAdAccountModalOpen, setAssignAdAccountModalOpen] =
    useState(false);
  const selectedSiterefs = useMemo(() => {
    if (siterefs && siterefs.length > 0 && customerSiteIds?.length) {
      const selected: Siteref[] = [];
      for (const siteref of siterefs) {
        if (
          customerSiteIds.includes(siteref.csid) &&
          siteref.reftype === type
        ) {
          selected.push(siteref);
        }
      }
      return selected;
    }
    return [];
  }, [siterefs, customerSiteIds, type]);
  const [showMore, setShowMore] = useState(false);

  const notSelfOnboardedIds = useMemo(() => {
    return notSelfOnboardedAccounts
      .filter((el) =>
        el.source === "facebook" ? "fb" === type : el.source === type
      )
      .map((el) => el.id);
  }, [notSelfOnboardedAccounts, type]);

  useEffect(() => {
    setStoreErrorMessage("");
  }, [customerSiteIds]);

  const storeToAccountMap = useMemo(() => {
    return selectedSiterefs.reduce(
      (acc, siteref) => {
        if (!acc[siteref.refid]) {
          const adAccount = adAccounts?.find((el) => el.id === siteref.refid);
          acc[siteref.refid] = { stores: [], adAccount: adAccount ?? null };
        }
        const storeName = stores?.find((el) => el.csid === siteref.csid)
          ?.description;
        acc[siteref.refid].stores.push(storeName ?? siteref.csid);
        return acc;
      },
      {} as Record<string, StoreToAdAccountMap>
    );
  }, [adAccounts, selectedSiterefs, stores]);
  const connectedAdAccountsKeys = Object.entries(storeToAccountMap)
    .map(([key, value]) => value.adAccount?.id)
    .filter(Boolean)
    .join(",");

  const unconnectedOrganisationStores = useMemo(() => {
    return (
      organisationStores?.filter(
        (store) =>
          !selectedSiterefs.some((siteref) => siteref.csid === store.csid)
      ) ?? []
    );
  }, [selectedSiterefs, organisationStores]);
  const uniqueRefs = useMemo(() => {
    return [...new Set(selectedSiterefs.map((el) => el.refid))];
  }, [selectedSiterefs]);
  let siterefContent;

  if (isPending) {
    siterefContent = (
      <div
        role="status"
        className="self-start mt-6 animate-pulse w-full  flex-1"
      >
        <div className="h-4 w-40 rounded-md bg-gray-200 dark:bg-gray-700" />
        <div className="h-4 w-32 mt-4 rounded-md bg-gray-200 dark:bg-gray-700" />
      </div>
    );
  } else if (selectedSiterefs.length > 0) {
    siterefContent = (
      <div className="mt-4 flex-1">
        <ul className="list-none list-inside mt-2 transition-all duration-200">
          {Object.entries(storeToAccountMap)
            .slice(0, showMore ? undefined : MAX_REFS_TO_SHOW)
            .map(([refid, data]) => (
              <li key={refid} className="mt-3">
                <div className="flex items-center gap-2">
                  <p className="font-medium">
                    {data.adAccount?.name
                      ? `${data.adAccount.name} (${data.adAccount.id})`
                      : refid}
                  </p>
                  {notSelfOnboardedIds.includes(refid) && (
                    <span className="text-sm text-yellow-500 ml-2 inline-flex items-center gap-1">
                      <InformationCircleIcon className="size-4" /> Please
                      reconnect
                    </span>
                  )}
                </div>
                <ul className="mt-1 text-foreground-soft">
                  {data.stores.map((csid) => (
                    <li key={csid}>{csid}</li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
        {uniqueRefs.length > MAX_REFS_TO_SHOW ? (
          <button
            onClick={() => setShowMore((curr) => !curr)}
            className="text-sm text-primary-light hover:text-primary"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        ) : null}
      </div>
    );
  } else {
    siterefContent = (
      <div className="self-start mt-4 flex-1">
        <p className="font-semibold text-sm text-foreground-soft text-left">
          No accounts connected...
        </p>
      </div>
    );
  }

  const onModalOpen = () => {
    setSelectStoreModalOpen(true);
  };
  const onModalClose = () => {
    setSelectStoreModalOpen(false);
  };

  const onAssignAdAccountModalClose = () => {
    setAssignAdAccountModalOpen(false);
  };
  const onAssignAdAccountModalOpen = () => {
    setAssignAdAccountModalOpen(true);
  };

  const connectButtonDisabled = integrations[type].disabled;
  // ||
  // (!!auth?.payload?.rol && auth?.payload?.rol >= 128);
  const tooltipMessage =
    selectedStoreIds.length > 1 ? (
      "Please select exactly 1 store from the store selector on the top-left to connect an ad account."
    ) : !!auth?.payload?.rol && auth?.payload?.rol >= 128 ? (
      "Admin users can't connect ad accounts."
    ) : (
      <div className="w-96">
        Ad integrations are only enabled in our production dashboard. Please go
        to{" "}
        <a
          href={`https://dashboard.tracify.ai${asPath ?? ""}`}
          className="underline"
          target="_blank"
        >
          https://dashboard.tracify.ai
        </a>{" "}
        to access them.
      </div>
    );
  const buttonComponent =
    selectedSiterefs.length > 0 ? (
      <Button
        variant="secondary"
        size="sm"
        onClick={onAssignAdAccountModalOpen}
      >
        <span className="flex items-center">
          <SettingsIcon className="h-5 w-5 mr-2" />
          Configure
        </span>
      </Button>
    ) : connectButtonDisabled ? (
      <Tooltip>
        <TooltipTrigger>
          <Button
            variant="primary"
            disabled={connectButtonDisabled}
            loading={requestSiterefCreateMutation.isPending}
            onClick={onModalOpen}
            size="sm"
          >
            <span className="flex items-center">
              <LinkIcon className="h-5 w-5 mr-2" />
              Connect Account
              {connectButtonDisabled ? (
                <InformationCircleIcon className="h-5 w-5 ml-2 text-on-primary" />
              ) : null}
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>{tooltipMessage}</TooltipContent>
      </Tooltip>
    ) : (
      <Button
        variant="primary"
        disabled={connectButtonDisabled}
        loading={requestSiterefCreateMutation.isPending}
        onClick={onModalOpen}
        size="sm"
      >
        <span className="flex items-center">
          <LinkIcon className="h-5 w-5 mr-2" />
          Connect Account
          {connectButtonDisabled ? (
            <InformationCircleIcon className="h-5 w-5 ml-2 text-on-primary" />
          ) : null}
        </span>
      </Button>
    );

  return (
    <>
      <AssignAdAccountModal
        onClose={onAssignAdAccountModalClose}
        open={assignAdAccountModalOpen}
        type={type === "fb" ? "facebook" : type}
        enableAddNewAccount
        key={type + selectedOrganisationData?.id + connectedAdAccountsKeys}
      />
      <InitializeAdAccountFlowModal
        onClose={onModalClose}
        open={selectedStoreModalOpen}
        integration={integrations[type]}
        type={type}
      />
      <div className="rounded-lg bg-gray-100 dark:bg-gray-800 flex flex-col relative">
        <div className="p-6 flex-1">
          <div className="flex items-center gap-3">
            <div className="bg-gray-200 dark:bg-gray-700 w-12 h-12 flex items-center justify-center rounded-md">
              <Image
                src={integrations[type].img}
                alt={integrations[type].name}
                width={34}
                height={34}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              ></Image>
            </div>
            <p className="h4 text-foreground">{integrations[type].name}</p>
            {isAdAccountsPending || isPending || isAdAccountsRefetching ? (
              <Spinner />
            ) : null}
          </div>
          {siterefContent}
        </div>
        <div className="h-px w-full bg-gray-300 dark:bg-gray-700" />
        <div className="flex justify-between p-6">
          {unconnectedOrganisationStores.length === 0 ? (
            <div className="flex items-center">
              <CheckCircleIcon className="text-green-500 w-6 h-6" />
              <span className="ml-1 text-sm">All Stores Connected</span>
            </div>
          ) : unconnectedOrganisationStores.length ===
            (customerSiteIds?.length ?? 0) ? (
            <div className="flex items-center">
              <div className="h-5 w-5 rounded-full border-foreground border border-dashed" />
              <span className="ml-2 text-sm">No store connected</span>
            </div>
          ) : (
            <div className="flex flex-col justify-center">
              <button
                className="flex items-center"
                onClick={() => setShowPartiallyConnectedMore((curr) => !curr)}
              >
                <CheckCircleIcon className="w-6 h-6" />
                <span className="ml-1 text-sm">Partially Connected</span>
                <ChevronDownIcon
                  className={`w-4 h-4 ml-2 transform duration-100 ${
                    showPartiallyConnectedMore ? "rotate-180" : ""
                  }`}
                />
              </button>
              {showPartiallyConnectedMore ? (
                <div className="mt-1 text-xs">
                  <p>
                    Your following stores are not connected to an ad account
                    yet:
                  </p>
                  <ul className="list-disc list-inside pl-2 space-y-1">
                    {unconnectedOrganisationStores.map((store) => (
                      <li key={store.csid}>
                        <span className="-ml-1">{store.description}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          )}

          {buttonComponent}
        </div>
      </div>
    </>
  );
};

export default IntegrationBlock;
