import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { transformToAccounts } from "./transformations";
import { AdAccounts } from "./types";
import { AdConnectorAPIError } from "@api/types/backendTypes";

export const FetchAdAccountsByOrganisationSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
  organisationId: z.string({ required_error: "Organisation ID not available" }),
  source: z.enum(["facebook", "google", "tiktok", "pinterest"]),
});

export type FetchAdAccountsByOrganisationProps = z.infer<
  typeof FetchAdAccountsByOrganisationSchema
>;

async function fetchAdAccountsByOrganisation({
  token,
  organisationId,
  source,
}: FetchAdAccountsByOrganisationProps): Promise<AdAccounts> {
  if (!token) {
    throw new Error("User Session not available");
  }

  try {
    FetchAdAccountsByOrganisationSchema.parse({
      token,
      organisationId,
      source,
    });

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_AD_CONNECTOR_V1_BASE_URL}/account/${source}/${organisationId}/get-by-organisation`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return transformToAccounts(response.data ?? []);
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as AdConnectorAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unknown error occured!");
  }
}

type UseAdAccountsByOrganisationProps = {
  organisationId: string;
  source: "facebook" | "google" | "tiktok" | "pinterest";
};

export type UseAdAccountsByOrganisationOptions = {
  enabled?: boolean;
  onSuccess?: ((data: AdAccounts) => void) | undefined;
};

export function useAdAccountsByOrganisation(
  { organisationId, source }: UseAdAccountsByOrganisationProps,
  options?: UseAdAccountsByOrganisationOptions
) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["adAccountsByOrganisation", organisationId, source],
    queryFn: () =>
      fetchAdAccountsByOrganisation({
        token: data?.token!,
        organisationId,
        source,
      }),
    ...options,
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
