import { integrations } from "@components/Dashboards/SettingsDashboard/IntegrationBlock";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Button } from "@components/ui/button";
import {
  ChevronLeftIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { useLocalStorage } from "@lib/hooks/use-local-storage";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Modal } from "@ui";
import { CONSTANT_LABELS } from "constants/constants";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

const ReconnectAdAccountsModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const { notSelfOnboardedAccounts } = useSelectedStores();

  const hideReconnectModal = () => {
    setIsOpen(false);
  };

  const notSelfOnboardedChannels = [
    ...new Set(notSelfOnboardedAccounts.map((el) => el.source)),
  ];

  return (
    <Modal
      onClose={hideReconnectModal}
      open={isOpen}
      title={
        <div className="flex items-center">
          <InformationCircleIcon className="h-5 w-5 mr-1 text-yellow-500" />
          Please reconnect your{" "}
          {notSelfOnboardedChannels
            .map((el) => CONSTANT_LABELS[el] ?? el)
            .join(", ")}{" "}
          ad account(s)
          <div className="flex items-center gap-1 ml-2">
            {notSelfOnboardedChannels.map((el) => (
              <Image
                src={integrations[el as keyof typeof integrations]?.img}
                alt={integrations[el as keyof typeof integrations]?.name}
                width={24}
                height={24}
                key={el}
              ></Image>
            ))}
          </div>
        </div>
      }
    >
      <div className="flex flex-col">
        <div className="flex-1">
          <div className="bg-yellow-400/25 border border-yellow-400/80 rounded-md p-4 text-sm mb-12">
            In order to continue to provide you with data like the ad spend from
            your{" "}
            {notSelfOnboardedChannels
              .map((el) => CONSTANT_LABELS[el] ?? el)
              .join(", ")}{" "}
            ad account(s), please reconnect them to Tracify by clicking the
            “Reconnect” button below.
            <p className="mt-2">
              The re-authentication is necessary so that Tracify gets the access
              rights to your ad account(s) from your end which increases
              stability.
            </p>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <LinkWithPersistentQuery
            href="/settings/integrations"
            onClick={hideReconnectModal}
          >
            <Button variant="primary">Reconnect</Button>
          </LinkWithPersistentQuery>
        </div>
      </div>
    </Modal>
  );
};

export default ReconnectAdAccountsModal;
