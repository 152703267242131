import { useAuth } from "@lib/api-hooks";
import { integrations } from "./IntegrationBlock";
import React, { useCallback, useState } from "react";
import { Integration } from "./IntegrationBlock";
import { Note } from "@ui";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Button } from "@components/ui/button";
import { useRequestCreateSiteref } from "@lib/api-hooks/useSiteRefs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

type Props = {
  onClose: () => void;
  open: boolean;
  integration: Integration;
  type: "google" | "fb" | "tiktok" | "pinterest";
};

const InitializeAdAccountFlowModal = ({
  onClose,
  open,
  integration,
  type,
}: Props) => {
  const { data: auth } = useAuth();
  const { selectedOrganisationData, stores, selectedStoreIds } =
    useSelectedStores();
  const customerSiteIds = selectedOrganisationData?.customerSites?.map(
    (el) => el.id
  );
  const organisationStores = stores?.filter(
    (el) => customerSiteIds?.includes(el.csid)
  );
  const requestSiterefCreateMutation = useRequestCreateSiteref();

  const [storeErrorMessage, setStoreErrorMessage] = useState("");

  const onConnectAccount = useCallback(async () => {
    if (integration.disabled) return;

    setStoreErrorMessage("");

    if (!selectedOrganisationData) {
      setStoreErrorMessage(
        "You must select an organisation to connect an ad account."
      );
    } else {
      try {
        await requestSiterefCreateMutation.mutateAsync({
          organisationId: selectedOrganisationData?.id,
          type: type,
        });
      } catch (error: any) {
        setStoreErrorMessage(error.message);
        console.log(error);
        return;
      }
    }
  }, [
    integration.disabled,
    requestSiterefCreateMutation,
    selectedOrganisationData,
    type,
  ]);

  const content = (
    <div className="flex-1">
      <p>
        You will be connecting your {integration.name} Account to this entire
        organization. After a successful connection you will be able to map each
        one of your stores to their specific ad accounts.
      </p>
      <div className="my-4">
        <p className="text-sm text-foreground-soft font-semibold">
          Connecting to
        </p>
        <div className="mt-2 w-full rounded-md border px-3 py-2 text-base bg-gray-200 dark:bg-gray-700 border-gray-300 dark:border-gray-700">
          <p className="flex items-center">
            <BuildingOfficeIcon className="h-5 w-5 mr-1 text-foreground-soft flex-none" />

            {selectedOrganisationData?.name}
          </p>
          <ul className="text-sm ml-6 list-inside gap-1 mt-1">
            {organisationStores?.map((el) => (
              <li key={el.csid}>{el.description}</li>
            ))}
          </ul>
        </div>
      </div>
      {storeErrorMessage && (
        <p className="mt-4 font-semibold text-red-500">{storeErrorMessage}</p>
      )}
    </div>
  );

  let noteContent = null;

  if (type === "fb") {
    noteContent = (
      <ul className="list-disc list-inside space-y-1">
        <li>
          Business Portfolio(s) (Each portfolio requires a separate connection)
        </li>
        <li>Page(s)</li>
        <li>Ad account(s)</li>
      </ul>
    );
  } else if (type === "google") {
    noteContent = (
      <ul className="list-disc list-inside space-y-1">
        <li>Allow the permissions Google is asking you for</li>
      </ul>
    );
  } else if (type === "tiktok") {
    noteContent = (
      <ul className="list-disc list-inside space-y-1">
        <li>Ad Account Management</li>
        <li>Ads Management</li>
        <li>Reporting</li>
        <li>Creative Management</li>
        <li>DPA Catalog Management</li>
        <li>Ad Comments</li>
        <li>Measurement</li>
      </ul>
    );
  } else if (type === "pinterest") {
    noteContent = (
      <ul className="list-disc list-inside space-y-1">
        <li>
          Click “Give access” to all the permissions Pinterest is asking you for
        </li>
      </ul>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Connect a {integration.name} Account</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col">
          {content}
          <div className="bg-primary-300/15 border border-primary-300/70 rounded-md p-4 mt-4 text-sm space-y-4">
            <div className="space-y-1">
              <p className="font-semibold ">
                Please grant access to each of the following where you want
                Tracify to track data.
              </p>
              <p>
                Note: Your {integration.name} account must have access to each
                ad account you want to connect.
              </p>
            </div>
            {noteContent}
            <p>
              These permissions allow us to show your ad insights.{" "}
              <a
                href="https://knowledge.tracify.ai/en/articles/249883-why-does-tracify-need-permissions-to-my-ad-account"
                target="_blank"
                className="font-semibold hover:underline"
              >
                Learn more
              </a>
            </p>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              variant="primary"
              disabled={
                !!auth?.payload?.ema &&
                auth?.payload?.ema?.endsWith("@tracify.ai")
                  ? false
                  : integration.disabled
                // ||
                // (!!auth?.payload?.rol && auth?.payload?.rol >= 128)
              }
              loading={requestSiterefCreateMutation.isPending}
              onClick={onConnectAccount}
            >
              {integration.buttonText}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InitializeAdAccountFlowModal;
