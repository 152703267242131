import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { ShopDomain } from "./types";
import { ShopConnectorAPIError } from "@api/types/backendTypes";

const FetchShopDomainSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
});

export type FetchShopDomainsProps = z.infer<typeof FetchShopDomainSchema>;

async function fetchShopDomains({
  token,
}: FetchShopDomainsProps): Promise<ShopDomain[]> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_SHOP_CONNECTOR_V2_BASE_URL}/domains/list?limit=100000&offset=0`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // TODO transform API data to our type
    return response.data?.items ? (response.data?.items as ShopDomain[]) : [];
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ShopConnectorAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseShopDomainsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: ShopDomain[]) => void) | undefined;
};

export function useShopDomains(options?: UseShopDomainsOptions) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["shopDomains"],
    queryFn: () => {
      return fetchShopDomains({ token: data?.token! });
    },
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 0, // 0 seconds needed to overwrite global settings
    gcTime: 0, // 0 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
