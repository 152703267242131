"use client";

import { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";

export interface BannerMessage {
  id: string;
  type: "default" | "error" | "warning";
  message: string | React.ReactNode;
  action?: React.ReactNode;
  dismissible?: boolean;
}

interface BannerProps {
  messages: BannerMessage[];
  onDismiss: (id: string) => void;
}

export function Banner({ messages, onDismiss }: BannerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (messages.length === 0) return null;

  const currentMessage = messages[currentIndex];

  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : messages.length - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prev) => (prev < messages.length - 1 ? prev + 1 : 0));
  };

  const handleDismiss = (id: string) => {
    onDismiss(id);
    if (!messages[currentIndex + 1] && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getBannerStyles = (type: BannerMessage["type"]) => {
    switch (type) {
      case "error":
        return "bg-red-600 text-foreground";
      case "warning":
        return "bg-yellow-600 text-foreground";
      default:
        return "bg-purple-500 text-white";
    }
  };

  const getButtonStyles = (type: BannerMessage["type"]) => {
    switch (type) {
      case "error":
        return "bg-red-600 hover:bg-red-500 active:bg-red-700 text-foreground";
      case "warning":
        return "bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-700 text-foreground";
      default:
        return "bg-purple-500 hover:bg-purple-400 active:bg-purple-600 text-white";
    }
  };

  return (
    <div className="relative flex gap-4 w-full">
      {/* Banner Content */}
      <div
        className={cn(
          "flex items-center transition-colors justify-between gap-4 px-4 h-10 font-medium rounded-md flex-1",
          getBannerStyles(currentMessage.type)
        )}
      >
        <div className="flex flex-1 items-center gap-2 line-clamp-1">
          <ExclamationCircleIcon className="h-5 w-5 shrink-0" />
          <Tooltip delayDuration={2000}>
            <TooltipTrigger asChild>
              <div className="line-clamp-1">
                {typeof currentMessage.message === "string" ? (
                  <p>{currentMessage.message}</p>
                ) : (
                  currentMessage.message
                )}
              </div>
            </TooltipTrigger>
            <TooltipContent>{currentMessage.message}</TooltipContent>
          </Tooltip>
          {currentMessage.action ? currentMessage.action : null}
        </div>

        {currentMessage.dismissible ? (
          <Button
            variant="ghost"
            size="icon-sm"
            className={cn(
              "h-6 w-6 shrink-0 rounded-md",
              getButtonStyles(currentMessage.type)
            )}
            onClick={() => handleDismiss(currentMessage.id)}
          >
            <XMarkIcon className="h-4 w-4" />
          </Button>
        ) : null}
      </div>

      {/* Navigation Controls */}
      {messages.length > 1 ? (
        <div
          className={cn(
            "flex items-center px-4 py-2 w-32 justify-center text-xs text-muted-foreground rounded-md transition-colors",
            getBannerStyles(currentMessage.type)
          )}
        >
          <button
            onClick={goToPrevious}
            className={cn(
              "flex h-6 w-6 items-center justify-center rounded-md transition-colors",
              getButtonStyles(currentMessage.type)
            )}
            aria-label="Previous message"
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <span className="flex-1 text-center">
            {currentIndex + 1} of {messages.length}
          </span>
          <button
            onClick={goToNext}
            className={cn(
              "flex h-6 w-6 items-center justify-center rounded-md transition-colors",
              getButtonStyles(currentMessage.type)
            )}
            aria-label="Next message"
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
