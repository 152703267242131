import { useAuth, useStores } from "@lib/api-hooks";
import { useOrganisations } from "@lib/api-hooks/useOrganisations";
import { storeToDisplayName } from "@lib/util-functions/store";
import { TRACIFY_APP_STATE_VAR } from "constants/constants";
import {
  dashboardModeAtom,
  selectedOrganisationAtom,
  selectedStoreIdsAtom,
  selectedStoreOptionsAtom,
} from "atoms";
import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { useSiteRefs } from "@lib/api-hooks/useSiteRefs";
import { useAdAccounts } from "@lib/api-hooks/useAdAccounts/useAdAccounts";
import { AdAccount } from "@lib/api-hooks/useAdAccounts/types";

const useSelectedStores = () => {
  const scope = "kytron::read_events";
  const { data: stores, isPending, isError } = useStores({ scope });
  const { data: organisations } = useOrganisations();
  const { data: siterefs, isPending: isSiterefsPending } = useSiteRefs({});
  const { data: adAccounts, isPending: isAdAccountsPending } = useAdAccounts();
  const { data: auth } = useAuth();
  const [dashboardMode, setDashboardMode] = useAtom(dashboardModeAtom);
  const [selectedStoreIds, setSelectedStoreIds] = useAtom(selectedStoreIdsAtom);
  const [selectedOrganisation, setSelectedOrgansation] = useAtom(
    selectedOrganisationAtom
  );

  const connectedChannels = useMemo(() => {
    if (!siterefs) return [];
    if (selectedStoreIds?.length === 0) return [];
    return siterefs.reduce((acc, el) => {
      const reftype = el.reftype === "fb" ? "facebook" : el.reftype;
      if (selectedStoreIds.includes(el.csid) && acc.indexOf(reftype) === -1) {
        return [...acc, reftype];
      }
      return acc;
    }, [] as string[]);
  }, [selectedStoreIds, siterefs]);

  const notSelfOnboardedAccounts = useMemo(() => {
    if (!adAccounts || !siterefs) return [];
    if (selectedStoreIds?.length === 0) return [];
    const siterefsForSelectedStores = siterefs.filter((el) =>
      selectedStoreIds.includes(el.csid)
    );
    const notSelfOnboardedAdAccounts = adAccounts.reduce((acc, adAccount) => {
      if (!adAccount.isSelfOnboarded) {
        const matchingSiteRef = siterefsForSelectedStores.find(
          (ref) => ref.refid === adAccount.id
        );
        if (matchingSiteRef) {
          acc.push(adAccount);
        }
      }
      return acc;
    }, [] as AdAccount[]);

    return notSelfOnboardedAdAccounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adAccounts?.length, selectedStoreIds.join(","), siterefs?.length]);

  useEffect(() => {
    if (organisations && organisations?.length > 0 && selectedOrganisation) {
      const organisation = organisations.find(
        (el) => el.id === selectedOrganisation
      );

      if (
        dashboardMode !== "ecommerce" &&
        organisation &&
        !organisation?.featureFlags?.general_mode_enabled
      ) {
        // switch back to ecom mode for organisation that don't have the general mode enabled
        // will only run, when we didn't update in updateSelectedOrganisation callback
        setDashboardMode("ecommerce");
      }
    }
  }, [dashboardMode, organisations, selectedOrganisation, setDashboardMode]);
  const selectedOrganisationData = useMemo(() => {
    if (organisations && organisations?.length > 0 && selectedOrganisation) {
      return organisations.find((el) => el.id === selectedOrganisation);
    }
    return null;
  }, [organisations, selectedOrganisation]);

  const [storeOptions, setStoreOptions] = useAtom(selectedStoreOptionsAtom);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const updateSelectedOrganisation = useCallback(
    (storeIds: string[], shouldUpdateStores?: boolean) => {
      if (!organisations) return;

      const latestValue = storeIds[storeIds.length - 1];
      const organisation = organisations.find((orga) => {
        const store = orga.customerSites.find((el) => el.id === latestValue);
        if (store) return true;
      });

      if (organisation && !organisation?.featureFlags?.general_mode_enabled) {
        setDashboardMode("ecommerce");
      }
      setSelectedOrgansation(organisation ? organisation?.id : undefined);
      if (shouldUpdateStores && stores) {
        const organisationStoreIds =
          organisation?.customerSites.map((el) => el.id) ?? [];

        let clusterIndex: number;

        const storesToSet = stores.filter((el) => {
          const included =
            storeIds.includes(el.csid) &&
            organisationStoreIds.includes(el.csid);
          if (included && clusterIndex === undefined) {
            clusterIndex = el.pg_idx;
          }
          // only set stores which are in the same cluster
          return included && clusterIndex === el.pg_idx;
        });
        const storeIdsToSet = storesToSet.map((el) => el.csid);

        setSelectedStoreIds(storeIdsToSet);

        setStoreOptions(storesToSet.map((store) => storeToDisplayName(store)));
      } else if (shouldUpdateStores && !stores) {
        toast.error("Couldn't load Stores. Please try reloading the page");
      }

      return organisation;
    },
    [
      organisations,
      setDashboardMode,
      setSelectedOrgansation,
      setSelectedStoreIds,
      setStoreOptions,
      stores,
    ]
  );

  useEffect(() => {
    if (!stores || !organisations) return;
    const availableIds = stores.map((store) => store.csid);
    const selectedAvailableIds = selectedStoreIds.filter((id) =>
      availableIds.includes(id)
    );
    // set first one as default
    if (selectedAvailableIds.length > 0) {
      setIsInitialized(true);
      updateSelectedOrganisation(selectedAvailableIds, true);
    } else if (
      organisations.length > 0 &&
      stores?.length > 0 &&
      !isInitialized
    ) {
      setIsInitialized(true);
      const applicationState = window.localStorage.getItem(
        TRACIFY_APP_STATE_VAR
      );

      // added here, because we had the case where the user had access to stores from organisations
      // where he wasn't a part of and stores[0] below chose exactly such a store
      // we then failed to set the corresponding organisation because the user didn't have access
      // and the dashboard didn't load
      const firstStoreInFirstOrganisation = organisations[0]?.customerSites[0];
      // we don't want our test shop Dietrich Dauerdicht to always be selected first (for Tracify Master Users)
      // but rather a store that has some good data for all channels (0e18aa0e-ab36-4a3c-bb24-67c3f861ed02).
      // For all other users (that don't have the Dietrich Dauerdicht store) we just select the first one
      const defaultStore = auth?.payload?.ema?.endsWith("@tracify.ai")
        ? stores.find((el) =>
            process.env.NEXT_PUBLIC_API_MOCKING === "enabled"
              ? el.csid === "fb586490-6404-4da9-8cb1-89e9ebb38942" // id of the mock store
              : el.csid === "0e18aa0e-ab36-4a3c-bb24-67c3f861ed02"
          ) ?? stores[0]
        : stores.find((el) => el.csid === firstStoreInFirstOrganisation?.id) ??
          stores[0];

      let selectedStores = [defaultStore];
      if (applicationState) {
        const parsedState = JSON.parse(applicationState);
        const savedStoreIds = parsedState["selectedStoreIds"];
        if (savedStoreIds) {
          selectedStores = stores.filter(
            (store) => savedStoreIds?.includes(store.csid)
          );
        }
        if (selectedStores.length === 0) selectedStores = [defaultStore];
      }
      const storeIds = selectedStores.map((store) => store.csid);
      updateSelectedOrganisation(storeIds, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, organisations]);

  const onStoreChanged = (value: string | string[]) => {
    if (!stores || !organisations) return;
    if (!value || value === "" || value.length === 0) {
      updateSelectedOrganisation([]);
      setSelectedStoreIds([]);
      setStoreOptions([]);
      return;
    }
    const selectedStoreIds =
      typeof value === "string" ? value.split(",") : value;

    updateSelectedOrganisation(selectedStoreIds, true);
  };

  const selectedStores = useMemo(() => {
    if (!stores) return [];
    return stores.filter((store) => selectedStoreIds.includes(store.csid));
  }, [stores, selectedStoreIds]);

  return {
    selectedStoreIds,
    setSelectedStoreIds,
    selectedOrganisation,
    setSelectedOrgansation,
    onStoreChanged,
    storeOptions,
    setStoreOptions,
    isPending,
    selectedStores,
    isError,
    stores,
    selectedOrganisationData,
    organisations,
    connectedChannels,
    isSiterefsPending,
    notSelfOnboardedAccounts,
    adAccounts,
  };
};

export default useSelectedStores;
