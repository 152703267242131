import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";

const PatchAdAccountCsidsSchema = z.object({
  accountId: z.string(),
  source: z.union([
    z.literal("google"),
    z.literal("facebook"),
    z.literal("tiktok"),
    z.literal("pinterest"),
  ]),
  csids: z.array(z.string()),
});
export type PatchAdAccountCsidsInput = z.infer<
  typeof PatchAdAccountCsidsSchema
>;

const onPatchCsids = async (input: PatchAdAccountCsidsInput, token: string) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    PatchAdAccountCsidsSchema.parse(input);

    const response = await axios.patch(
      `${process.env.NEXT_PUBLIC_AD_CONNECTOR_V1_BASE_URL}/account/${input.source}/${input.accountId}/patch-csids`,
      { associated_csids: input.csids },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response?.data?.error ?? "Unknown error occurred.");
    }

    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ChiefAPIError;
        const firstErr = apiError.detail?.[0] ?? "Unknown error occurred.";
        throw new Error(firstErr?.msg ?? axiosError.message);
      }
    }
    if (err.message?.startsWith("Failed to connect the following account(s)")) {
      throw new Error(err.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const usePatchAdAccountCsids = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: PatchAdAccountCsidsInput) =>
      onPatchCsids(input, data?.token ?? ""),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adAccounts"] });
      queryClient.invalidateQueries({ queryKey: ["adAccountsByOrganisation"] });
      queryClient.invalidateQueries({ queryKey: ["siterefs"] });
    },
  });
};
